import MostrarInfo from "./componentes/mostrarInfo";

function App() {
  return (
    <div className="App">
      <MostrarInfo />
    </div>
  );
}

export default App;
